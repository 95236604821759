import { Box, Button, FormControl, FormGroup } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Loading";
import Error from "../Error";
import { fetchAssets, fetchAssetStartDate } from "../../util/http";
import TickerSelector from "../TickerSelector";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { isMarketClosed } from "../../util/Util";

export default function PositionSimulatorPanel({handleRunSimulation}){
    
    const {data: { data: { assets: tickerList } = {} } = {}, isPending: isTickerListPending, isError: isTickerListError, error: tickerListError} = useQuery({
        queryKey: ['assets list'],
        queryFn: () => fetchAssets(),
        staleTime: Infinity,
    });


    const [ticker, setTicker] = useState(null);
    const [date, setDate] = useState(null);
    const [expirationFrom, setExpirationFrom] = useState(null);
    const [cutoff, setCutoff] = useState('20500101');
    const [tickerStartDate, setTickerStartDate] = useState(null);

    const {data: startDateFromServer, isPending: isStartDatePending, isError: isStartDateError, error: startDateError} = useQuery({
        queryKey: ['asset start date', ticker],
        queryFn: () => fetchAssetStartDate(ticker),
        enabled: ticker !== null,
    });    

    useEffect(() => {
        if (!isStartDatePending && !isStartDateError && startDateFromServer){
            setTickerStartDate(dayjs(startDateFromServer.data.start_date));
        }
    }, [startDateFromServer, isStartDatePending, isStartDateError, ticker]);

    const handleTickerSelected = useCallback((event, value) => {
        if (ticker !== value){
            setTicker(value);
        }
    }, [ticker]);

    const handleDateChanged = useCallback(value => {
        const newDate = dayjs(value);
        
        if (!dayjs(date).isSame(newDate)){
            
            setDate(newDate.format('YYYYMMDD'));

            if (!expirationFrom || dayjs(expirationFrom).isBefore(newDate))
            {     
                setExpirationFrom(newDate.format('YYYYMMDD'));
            }
            if (!cutoff || dayjs(cutoff).isBefore(newDate))
                setCutoff(newDate.format('YYYYMMDD'));
        }        
    }, [date, cutoff, expirationFrom]);

    const handleExpirationFromChanged = useCallback(value => {
        const newExpFrom = dayjs(value);
        
        if (!dayjs(date).isSame(newExpFrom)){
            setExpirationFrom(newExpFrom);

            if (!date || date.isAfter(newExpFrom))
                setDate(newExpFrom);            
            if (!cutoff || cutoff.isBefore(newExpFrom))
                setCutoff(null);            

        }

    }, [date, cutoff]);

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        handleRunSimulation({
            ticker,
            date,
            expiration_from: expirationFrom,
            cutoff,
        });
    }, [ticker, date, expirationFrom, cutoff, handleRunSimulation]);    

    const shouldDisableDate = useCallback((date) => {
        return dayjs(date).isAfter(dayjs()) || dayjs(date).isBefore(tickerStartDate) || isMarketClosed(date);
    }, [tickerStartDate])    


    if (isTickerListPending)
        return <Loading/>;

    if (isTickerListError){
        return <Error error={tickerListError}/>
    }

    if (isStartDateError){
        return <Error error={startDateError}/>
    }

    return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', padding: 1/*, border: '1px solid'*/ }}>
        <FormControl 
            component="fieldset" 
            variant="standard"
        >
            <FormGroup 
                row 
                sx={{
                    gap: 6,
                    alignItems: 'center'
                }}
            >
                <TickerSelector
                    sx={{variant: 'filled', width: 150/*, border: "1px solid"*/}}
                    tickerList={tickerList}
                    label={"Ticker"}
                    value={ticker}
                    onChange={handleTickerSelected}
                />

                <DatePicker
                    shouldDisableDate={shouldDisableDate}
                    value={dayjs(date)}
                    label={"Date"}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small', variant: 'filled', style: {width : 200 }} }}
                    onChange={handleDateChanged}
                />

                <DatePicker
                    shouldDisableDate={shouldDisableDate}
                    label={"First Expiration Date"}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small', variant: 'filled', style: {width : 200 }} }}
                    value={dayjs(expirationFrom)}
                    onChange={handleExpirationFromChanged}
                />

                <DatePicker
                    label={"Last Expiration Date"}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small', variant: 'filled', style: {width : 200 }} }}
                    value={dayjs(cutoff)}
                />

                <Button 
                    disabled={!ticker || !date || !expirationFrom || !cutoff}
                    type="submit" 
                    variant="contained" 
                    color="primary" 
                    size="small" 
                    sx={{width: 80}}>
                        RUN
                </Button>
            </FormGroup>
        </FormControl>
        </Box>
    </LocalizationProvider>
    );
}