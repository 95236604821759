import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { fetchAreaOfInterestChart, getUserMeta, setUserMeta } from "../util/http";
import Loading from "../components/Loading";
import Error from "../components/Error";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { SAVE_KEY_INDEX_OVERVIEW_SELECTED_TAB } from "../util/Constants";
import IndexOverviewTab from "../components/index_overview/IndexOverviewTab";

export default function IndexOverviewPage(){

    const tabDisplayOrder = ['daily', 'weekly', 'monthly' , 'quarterly', 'all'];
    const idxDisplayOrder = ['SPY', 'SPX' , 'QQQ', 'IWM'];
      
    const { data: { data: chartData } = { data: [] }, isPending, isError, error } = useQuery({
        queryKey: ['areaOfInterestsChart', idxDisplayOrder.join(','), tabDisplayOrder.join(',')],
        queryFn: () => fetchAreaOfInterestChart(idxDisplayOrder.join(','), tabDisplayOrder.join(',')),
    });    

    const [selectedTab, setSelectedTab] = useState(0);

    const { data: loadedTab, isPending: isLoadingTabPending, isError: isLoadingTabError, error: loadingTabError } = useQuery({
        //queryKey: ['savedTab'],
        queryKey: ['user_meta'],
        queryFn: () => getUserMeta(),
        select: (data) => data ? data[SAVE_KEY_INDEX_OVERVIEW_SELECTED_TAB] : null
      });

    const handleTabChange = useCallback((event, newValue) => {
        if (newValue !== selectedTab)
        {
          setUserMeta(SAVE_KEY_INDEX_OVERVIEW_SELECTED_TAB, newValue);
          setSelectedTab(newValue);
        }
    }, [selectedTab]);

    useEffect(() => {
    if (!isLoadingTabPending && !isLoadingTabError)
    {
        setSelectedTab(process.env.NODE_ENV === 'development' ? 0 : (loadedTab ? loadedTab : 0));
    }
    },[isLoadingTabPending, isLoadingTabError, loadedTab]);
      
    

    if (isPending || isLoadingTabPending)
        return <Loading />;

    if (isError) {
        return <Error error={error} />;
    }

    if (isLoadingTabError) {
        return <Error error={loadingTabError} />;
    }

    return (
        <Grid container spacing={1}>
            <Grid item sx={{minHeight:'95vh', mx: '2%', width: '100%'}}>
                <Tabs value={selectedTab} onChange={handleTabChange} centered={false} >
                    {tabDisplayOrder.map((tabName) => (
                        <Tab label={tabName} />
                    ))}    
                </Tabs> 
                {tabDisplayOrder.map((tabName, index) => (
                    selectedTab === index && (
                        <Box key={index} sx={{ height: '97%' }}>
                            <IndexOverviewTab 
                                chartData={chartData[tabName]}
                                indexesToDisplay={idxDisplayOrder}
                            />
                        </Box>
                    )
                ))}
            </Grid>
        </Grid>
    );
}