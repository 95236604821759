import { Grid } from "@mui/material";
import PositionSimulatorPanel from "../components/position_simulator/PositionSimultorPanel";
import { useCallback, useState } from "react";
import PositionSimulatorChart from "../components/position_simulator/PositionSimulatorChart";


export default function PositioningSimulatorPage(){

    const [params, setParams] = useState(null);

    const handleRunSimulation = useCallback((p) => {
        setParams(p);
    }, [setParams]);

    return (
        <Grid container spacing={1}>
            <Grid item sx={{minHeight:'6vh', mx: '2%', width: '100%'}}>
                <PositionSimulatorPanel handleRunSimulation={handleRunSimulation}/>
            </Grid>
            <Grid item sx={{minHeight:'94vh', mx: '2%', width: '100%'}}>
                <PositionSimulatorChart params={params}/>
            </Grid>
        </Grid>

    );
}