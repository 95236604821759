import { useQuery } from "@tanstack/react-query";
import { fetchAreaOfInterestChart } from "../../util/http";
import { Box, Grid, IconButton } from "@mui/material";
import { useCallback, useState } from "react";
import Loading from "../Loading";
import Error from "../Error";
import { Fullscreen } from "@mui/icons-material";
import AreasOfInterestChart from "./AreasOfInterestChart";

export default function AreasOfInterestTab({selectedTicker}){   

    const chartsDisplayOrder = ['weekly', 'monthly' , 'quarterly', 'all'];

    const [chartsStatus, setChartsStatus] = useState(Array(4).fill({
        hovered: false,
        fullscreen: false,
    }));
    
    const { data: { data: chartData } = { data: [] }, isPending, isError, error } = useQuery({
        queryKey: ['areaOfInterestsChart', selectedTicker, chartsDisplayOrder.join(',')],
        queryFn: () => fetchAreaOfInterestChart(selectedTicker, chartsDisplayOrder.join(',')),
        enabled: selectedTicker !== null && selectedTicker !== undefined,
    });

    const handleFullscreenClosed = useCallback(() => {
        const index = chartsStatus.findIndex((chart) => chart.fullscreen);
        setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], fullscreen: false },
        ...prevStatus.slice(index + 1)]);
    }, [chartsStatus]);

    if (isPending)
        return <Loading />;

    if (isError) {
        return <Error error={error} />;
    }

    const isChartFullscreen = chartsStatus.find((chart) => chart.fullscreen) !== undefined;

    //console.log('areas of interest', chartMetadata);
    return (
        <Grid container rowSpacing={0} columnSpacing={0} /*sx={{  '& > *': { border: '3px solid white' } }}*/>
            {!isChartFullscreen && chartsDisplayOrder.map((interval, index) => (
                <Grid item
                    key={index}
                    xs={6}
                    style={{ height: '44vh', marginBottom: '2vh' }}
                >
                    <Box
                        sx={{ height: '100%', padding: '0px !important' }}
                        position='relative'
                        border="1px solid #ccc"
                        borderRadius={4}
                        onMouseEnter={() => setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], hovered: true }, ...prevStatus.slice(index + 1)])}
                        onMouseLeave={() => setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], hovered: false }, ...prevStatus.slice(index + 1)])}
                    >
                        {chartsStatus[index].hovered && !chartsStatus[index].fullscreen &&
                            <Box position='absolute' top={0} right={0} zIndex={2000} >
                                <IconButton onClick={() => setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], fullscreen: true }, ...prevStatus.slice(index + 1)])}>
                                    <Fullscreen />
                                </IconButton>
                            </Box>
                        }
                        <AreasOfInterestChart
                            ticker={selectedTicker}
                            title={interval}
                            chartData={chartData[interval][selectedTicker]}
                        />                                                    
                    </Box>
                </Grid>
            ))}
            {!chartData && (
                <Box border="1px solid #ccc" borderRadius={4} sx={{ minHeight: '100%', minWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', direction: 'column' }}>
                    <p>No data available for this date.</p>
                </Box>
            )}
            {!isError && isChartFullscreen &&
                <AreasOfInterestChart
                    ticker={selectedTicker}
                    title={chartsDisplayOrder[chartsStatus.findIndex((chart) => chart.fullscreen)]}
                    chartData={chartData[chartsDisplayOrder[chartsStatus.findIndex((chart) => chart.fullscreen)]][selectedTicker]}
                    fullscreen
                    handleFullscreenClosed={handleFullscreenClosed}
                />
            }

        </Grid>
    ); 
}