import { Autocomplete, TextField } from "@mui/material";
import similarityMatchFilter from "../util/SimiarityMatchFilter";

export default function TickerSelector({tickerList, excludedTickers, label, autoFocus, ...props}){

    return (
        <Autocomplete
            {...props}
            options={tickerList}
            renderInput={(params) => <TextField {...params} label={label} autoFocus={autoFocus ? autoFocus : false} variant="outlined" />}
            filterOptions={(options, { inputValue }) => similarityMatchFilter(options, { inputValue, excludedOptions : (excludedTickers || []).map((ticker) => ticker.id) })}
        />
    );
}
