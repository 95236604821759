import { Fullscreen } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import { useCallback, useState } from "react";
import AreasOfInterestChart from "../areas_of_interest/AreasOfInterestChart";

export default function IndexOverviewTab({chartData, indexesToDisplay}){

    const [chartsStatus, setChartsStatus] = useState(Array(4).fill({
        hovered: false,
        fullscreen: false,
    }));

    const handleFullscreenClosed = useCallback(() => {
        const index = chartsStatus.findIndex((chart) => chart.fullscreen);
        setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], fullscreen: false },
        ...prevStatus.slice(index + 1)]);
    }, [chartsStatus]);

    const isChartFullscreen = chartsStatus.find((chart) => chart.fullscreen) !== undefined;

    return (
        <Grid container rowSpacing={0} columnSpacing={0} /*sx={{  '& > *': { border: '3px solid white' } }}*/>
            {!isChartFullscreen && indexesToDisplay.map((indexName, index) => (
                <Grid item
                    key={index}
                    xs={6}
                    style={{ height: '44vh', marginBottom: '2vh' }}
                >
                    <Box
                        sx={{ height: '100%', padding: '0px !important' }}
                        position='relative'
                        border="1px solid #ccc"
                        borderRadius={4}
                        onMouseEnter={() => setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], hovered: true }, ...prevStatus.slice(index + 1)])}
                        onMouseLeave={() => setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], hovered: false }, ...prevStatus.slice(index + 1)])}
                    >
                        {chartsStatus[index].hovered && !chartsStatus[index].fullscreen &&
                            <Box position='absolute' top={0} right={0} zIndex={2000} >
                                <IconButton onClick={() => setChartsStatus((prevStatus) => [...prevStatus.slice(0, index), { ...prevStatus[index], fullscreen: true }, ...prevStatus.slice(index + 1)])}>
                                    <Fullscreen />
                                </IconButton>
                            </Box>
                        }
                        <AreasOfInterestChart
                            ticker={indexName}
                            title={indexName}
                            chartData={chartData[indexName]}
                        />                                                    
                    </Box>
                </Grid>
            ))}
            {!chartData && (
                <Box border="1px solid #ccc" borderRadius={4} sx={{ minHeight: '100%', minWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', direction: 'column' }}>
                    <p>No data available for this date.</p>
                </Box>
            )}
            {chartData && isChartFullscreen &&
                <AreasOfInterestChart
                    ticker={indexesToDisplay[chartsStatus.findIndex((chart) => chart.fullscreen)]}
                    title={indexesToDisplay[chartsStatus.findIndex((chart) => chart.fullscreen)]}
                    chartData={chartData[indexesToDisplay[chartsStatus.findIndex((chart) => chart.fullscreen)]]}
                    fullscreen
                    handleFullscreenClosed={handleFullscreenClosed}
                />
            }

        </Grid>        
    );
}