import { useMemo } from "react";
import StandardChart from "../StandardChart";
import _ from 'lodash';
import ChartFullscreenDialog from "../ChartFullscreenDialog";

export default function AreasOfInterestChart({ticker, chartData, title, fullscreen, handleFullscreenClosed}){   
    
    const option = useMemo(() => {
        const seriesKeys = ['l_delta', 'CallOI', 'PutOI'];
        const seriesNames = ['Interest Level', 'Call OI', 'Put OI'];
        const maxXLabels = 50;        
        const callMax = Math.max(...chartData[seriesKeys[1]]);
        const putMin = Math.min(...chartData[seriesKeys[2]]);
        const yMax = Math.max(callMax, Math.abs(putMin));
    
        return ({
            title: {
                text: title,
                left: 'center',
                top: '5px',//4
            },
            grid: {
                left: '10px',//'4%',
                right: '10px', //'4%',
                bottom: '10px',//'10%',
                top: '40px',//25
                containLabel: true,
            },
            legend: {
                data: seriesNames,
                icon: 'rectangle',
                top: '23px',//15
                left: 'center',
            },
            tooltip: {
                trigger: 'item',                
            },            
            xAxis:
            {
                type: 'category',
                data: chartData['price'],
                boundaryGap: true,
                axisPointer: {
                    type: "shadow",
                    show: true,
                    //label: { show: true },
                },
                axisTick: {
                    show: false
                },
                axisLabel: {
                    interval: Math.round(chartData['price'].length / maxXLabels) - 1,
                    rotate: 45,
                    fontSize: 8,
                },
            },        
            yAxis: [
                {
                    type: 'value',
                    position: 'left',
                    axisLine: {
                        show: true
                    },
                    axisTick: {
                        alignWithLabel: true,
                        interval: 'auto',
                        customValues: [0.2, 0.5,  0.8]
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#f00',
                            width:1
                        },
                    },
                    axisLabel: {
                        formatter: '{value}',
                        customValues: [0.2, 0.5, 0.8]
                    }
                },            
                {
                    position: 'right',
                    type: 'value',
                    min: -yMax,
                    max: yMax,
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        formatter: function (value) {
                            if (value && value % 1000000 === 0)
                                return (value / 1000000) + 'M';
                            else if (value && value && value % 1000 === 0)
                                return (value / 1000) + 'K';
                            else
                                return value;
                        },
                        fontSize: 8,
                    },
                },
            ],
            series: [
                {
                    name: seriesNames[1],
                    data: chartData[seriesKeys[1]],
                    type: 'bar',
                    stack: 'Open Interest',
                    yAxisIndex: 1,
                    z: 2,
                },
                {
                    name: seriesNames[2],
                    data: chartData[seriesKeys[2]],
                    type: 'bar',
                    stack: 'Open Interest',
                    yAxisIndex: 1,
                    z: 2,
                },
                {
                    name: seriesNames[0],
                    data: chartData[seriesKeys[0]],
                    type: 'line',
                    yAxisIndex: 0,
                    symbol: 'none',
                    connectNulls: true,
                    z: 3,
                    markLine: {
                        data: [
                            {
                                name: 'Price', 
                                xAxis: _.sortedIndex(chartData['price'], Object.keys(chartData['current_level'])[0]),
                            }
                        ],
                        symbol: ['none', 'none'],
                        label: {
                            show: false,
                        },
                        lineStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 1,
                                y2: 1,
                                colorStops: [
                                    { offset: 0, color: '#00FF00' }, // Bright green start
                                    { offset: 1, color: '#66FF66' }  // Slightly darker green end
                                ],
                            },
                            type: 'solid',
                            opacity: 0.5,
                        },
                        tooltip: {
                            show: false  // Disable tooltip for markLine
                        }                    
                    }                
                },                
            ],
        })
    }, [title, chartData]);

    const fullscreenOption = useMemo(() => ({
        ...option,
        xAxis: {
            ...option.xAxis,
            axisLabel: {
                ...option.xAxis.axisLabel,
                interval: Math.round(option.xAxis.axisLabel.interval / 3),
            }
        }
    }), [option]);

    return (
        <>
            {!fullscreen &&
                <StandardChart
                    option={option}
                />
            }
            {fullscreen && 
                <ChartFullscreenDialog
                    open={fullscreen}
                    option={fullscreenOption}
                    handleClose={handleFullscreenClosed}
                />
            }
        </>
    );

}